@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
html,
body,
#root,
.app,
.content {
  font-family: "Source Sans Pro", sans-serif;
  background-color: "#f0f0f0";
}

.app {
  display: flex;
  position: relative;
  /* width: 100%; */
}

::-webkit-scrollbar {
  width: 2.25px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide the up and down arrows for number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield; /* Standard */
  -moz-appearance: textfield; /* For Firefox */
}
